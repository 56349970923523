<template>
  <div class="package_for_choose_component-wrap">
    <slot></slot>
    <img
      v-if="canEditPackage"
      class="min-title-close"
      alt="x"
      src="../../../../public/icon/newStanard/关闭备份2.png"
      @click="$emit('close')"
    />
  </div>
</template>

<script>
export default {
  name: 'packageForChooseComponent',
  props: {
    canEditPackage: {
      type: Boolean,
      default() {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.package_for_choose_component-wrap {
  display: flex;
  align-items: center;
}
.min-title-close {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
</style>
