/*
 *协议标准
 *  所有内容首行缩进 4 个字符
 *  h5    小标题
    p     段落
 *  b     加粗文本
 *  span  普通文本

<h5>  </h5>

<p><span>  </span></p>

<p><b>  </b></p>

 */
export const registerContent = `
<p><span>感谢您选择全倾服务。全倾服务协议（以下简称“本协议”）由北京全倾科技有限公司(以下简称"全倾"或“我们”）和您签订。本协议就全倾平台提供的技术项目外包及承接服务相关权利义务进行规范，本服务条款对您及全倾均具有法律效力。  </span></p>

<h5>【特别提示】  </h5>

<p><b>
1、在使用全倾服务之前，请您务必仔细阅读本协议内容，尤其是字体加粗部分。请您务必审慎阅读、充分理解各条款内容，特别是隐私政策、用户协议、免责以及争议管辖与法律适用的条款。 除非您已阅读并接受本协议所有条款，否则您无权使用“全倾”提供的服务。
</b></p>

<p><span>
2、“全倾”的服务仅向有完全民事行为能力的自然人、法人或其他组织提供。您使用全倾服务时应确认为具备相应民事行为能力的自然人、法人或其他组织。未成年人应当在监护人陪同和指导下阅读本协议，并在使用本协议项下服务前取得监护人的同意，若您不具备签署与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果，且“全倾”有权直接注销您的账户。若您在使用“全倾”服务过程中造成我们损失的，我们有权向您及您的监护人索偿。
</span></p>

<p><span>
3、如您对本协议内容或页面提示信息有疑问，请立即停止当前操作，退出页面，勿进行下一步操作。您可通过联系我们在线客服进行咨询，以便我们为您解释和说明。您在注册页面完成信息填写、勾选阅读并同意本协议并完成全部注册程序后确认即表示您愿意签署本协议并受本协议约束；您的注册、登录、使用等行为将视为对本协议的接受，并视为您同意接受本协议各项条款的约束，本协议将构成您和“全倾”之间直接有约束力的法律文件。
</span></p>

<p><span>
4、如我们对本协议进行修改，我们将以网站公告、用户后台或通过其他联系方式向您通知/送达的相关规则、政策、通知，前述修订或相关规则，自通知/送达之日起正式生效。如您不同意相关变更内容或我们通知之任何内容，请停止使用“全倾”服务。如果您继续使用“全倾”服务，则视为您已认可并无条件接受本协议变更及我们向您通知之全部内容。
</span></p>

<h5>一、定义  </h5>

<p><span>
1、全倾平台，是指北京全倾科技有限公司及其关联方运营的全倾网站www.quanqingkeji.com、全倾APP、小程序、微信公众号等。前述关联方指由北京全倾科技有限公司直接持有该企业50%以上股权、投票权或管理权之主体。
</span></p>

<p><span>
2、用户，包含注册用户和非注册用户。注册用户是指通过全倾平台完成全部注册程序后，使用全倾服务的用户。非注册用户指虽未进行注册，但以直接登录全倾平台方式，或通过其他网站/客户端直接或间接方式使用全倾平台服务的用户。
</span></p>

<h5>二、 服务内容及服务的变更、中断、终止</h5>

<p><span>
1、“全倾”的具体服务内容以“全倾”平台实际情况提供之内容为准，包括但不限于发布项目需求、公司入驻、团队入驻、个人入驻、技术产品购买等。“全倾”仅提供相关服务，除此之外与本服务有关的设备（如电脑、调制解调器及其他与接入互联网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费）均应由您自行负担。
</span></p>

<p><span>
2、“全倾”保留根据实际情况随时调整“全倾”平台提供的服务种类、形式的权利。“全倾”不承担因业务调整给您造成的损失。
</span></p>

<p><span>
3、“全倾”因业务调整或平台自身需要进行改版的，“全倾”将以网站公告等方式向您告知，调整在告知后正式生效，此类情况不视为“全倾”违约。
</span></p>

<p><span>
4、您理解网络服务具有特殊性（包括但不限于服务期稳定性问题、恶意的网络攻击等行为的存在及其他“全倾”无法控制的情况），若发生服务中断或终止部分/全部服务的情况，“全倾”将尽可能及时通过网站公告、系统通知、短信或其他合理方式向您告知。“全倾”保留单方变更、中断或终止部分网络服务的权利。
</span></p>

<h5>三、账户注册、安全及使用</h5>
<h5>（一）注册</h5>

<p><b>
1、您完成注册程序或以其他“全倾”认可的渠道实际使用“全倾”服务后，全倾会向您提供唯一编号的全倾账户，并由您自行设置密码。全倾识别特定您的方式是且仅是您使用特定账户，并输入与之匹配的密码进行登录。故您应保证账户和密码的安全，并对您注册的账户通过登录密码校验后实施的一切行为负责。对于他人使用任何手段获取您账户及其密码登录全倾并实施任何的行为，全倾都视为您本人的行为。
</b></p>

<p><b>
2、您在注册账号或使用服务过程中，应向“全倾”提供合法、真实、准确的个人信息，若您填写的个人信息有变动，您应及时进行更新，以免影响服务体验。若您故意提供虚假的身份信息进行注册，发布虚假信息的，视为严重违反本协议，“全倾”有权立即暂停或终止您的账号并停止提供服务。虚假注册、发布虚假信息给“全倾”造成经济、名誉损失的，“全倾”有权向您全额索赔。
</b></p>

<p><span>
3、如您为企业用户，您应保证其职务行为已获得有效授权，包括但不限于上传/发布信息、确认需求/服务信息等；您应保证上传资料的真实准确性。如因您公司资料非真实造成的一切后果概由您自行承担。
</span></p>

<p><span>
4、用户名由您自行设置，请注意文明用语；不得以党和国家领导人或其他社会名人真实姓名、字号、艺名、笔名注册； 不得以国家机构或其他机构名称注册、 不得注册不文明、不健康的名称，及包含歧视、侮辱、猥亵类词语的名称；不得注册易产生歧义、引起他人误解或其他不符合法律规定的账号。若“全倾”发现您的用户名不符合前述规定或含有其他不恰当文字的，“全倾”有权单方注销您的账号，因此造成的一切后果概由您自行承担。
</span></p>

<p><span>
5、您理解并同意，一经注册用户账号，即视为您同意全倾及/或其关联公司可以通过拨打电话、发送短信或者电子邮件等方式向您注册时填写的手机号码、电子邮箱等发送、告知相应的产品服务广告信息、促销优惠等营销信息；您如果不同意接收相关信息，您可以通过客服或相关提示进行退订。
</span></p>

<h5>（二）账号安全 </h5>
<p><span>
1、您有义务保证密码及帐号的安全，并对利用该密码及帐号所进行的一切活动负全部责任，包括任何经由全倾上载、张贴、发送电子邮件或任何其它方式传送的资讯、资料、文字、软件、音乐、音讯、照片、图形、视讯、信息或其它资料，无论系公开还是私下传送，均由内容提供者承担责任。
</span></p>

<p><span>
2、除非有法律规定且征得全倾的同意，否则，您账户和密码不得以任何方式转让、赠与或继承（与账户相关的财产权益除外）、不得将账号提供给任何第三方进行使用。如因此造成用户隐私泄露或“全倾”平台损失的（包括但不限于经济损失、名誉损失等），您均应当承担相应的责任。
</span></p>

<p><span>
3、“全倾”承诺非经法定原因、本协议的约定或您的事先许可，“全倾”不会向任何第三方透露您的注册账号、手机号码等非公开信息。如您发现任何人不当使用您的账户或者有任何其他可能危及您的账户安全的情形时，应该立即修改帐号密码并妥善保管，您还可立即通知“全倾”平台，我们在收到您的通知后会在合理时间内处理您的账号问题。因黑客行为或您的保管疏忽导致账号非法使用，全倾平台不承担任何责任，你应通过司法、行政等救济途径向侵权行为人追偿。
</span></p>

<p><span>
4、“全倾”有权根据自身的判定，在怀疑账号被不当使用时，拒绝或限制账号使用，或者直接注销该账号，同时终止本协议。
</span></p>

<h5>（三）账号使用规范 </h5>

<p><span>
1、您同意，遵守法律及本协议规定，善意使用“全倾”平台，且保证不会利用“全倾”进行任何违法、违背社会公共利益或公共道德、损害他人的合法权益等不正当活动。
</span></p>

<p><b>
2、您不得在“全倾”平台发布或以其它方式传送、传播含有下列内容之一的信息：
</b></p>

<p><b>
（1）违反国家法律法规禁止性规定的；
</b></p>

<p><b>
（2）政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；
</b></p>

<p><b>
（3）欺诈、虚假、不准确或存在误导性的；
</b></p>

<p><b>
（4）侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；
</b></p>

<p><b>
（5）侮辱或者诽谤、侵害他人隐私或其他合法权利的；
</b></p>

<p><b>
（6）粗俗、猥亵或其它道德上令人反感的内容的；
</b></p>

<p><b>
（7）存在可能破坏、篡改、删除、影响“全倾”任何系统正常运行或未经授权秘密获得“全倾”及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的；
</b></p>

<p><b>
（8）其他违背社会公共利益或公共道德或经“全倾”评估不适合在“全倾”平台上发布的。
</b></p>


<p><b>
3、您在使用“全倾”平台的过程中，您应保证：
</b></p>

<p><span>
（1）您在“全倾”实施的所有行为均遵守国家法律法规等规范性文件、遵守“全倾”平台各项规则的规定和要求。
</span></p>

<p><span>
（2）未经“全倾”同意，不擅自对“全倾”平台上任何数据进行商业性使用，包括但不限于复制、传播“全倾”平台上展示的资料、数据、文章、链接等。
</span></p>

<p><span>
（3）不使用任何可能破坏、篡改、删除、影响“全倾”任何系统正常运行的装置、病毒、木马、爬虫等软件、程序代码。
</span></p>

<p><span>
（4）不得采用作弊、欺诈或利用系统漏洞等不正当手段，获取不正当利益。
</span></p>

<p><b>
4、如因您发布不当信息、违反法律法规、本协议规定或“全倾”平台各项规则，导致的任何纠纷、争议、损失、赔偿等一切责任均由您自行承担，“全倾”不承担任何责任及赔偿，如您因此造成全倾被第三人索赔的，造成全倾损失的，您应承担全部责任，包括但不限于各种赔偿费、诉讼代理费及“全倾”为此支出的其它合理费用。
</b></p>

<p><b> 
5、您同意并理解，对于您在全倾上的行为，全倾有权单方认定您行为是否构成对本协议及相关规则的违反，并据此采取相应处理措施。若您违反本协议约定及承诺，“全倾”有权依据法律法规及本协议的约定，对您的账号作出相应处理且无须征得您的同意或提前向您告知，包括但不限于屏蔽或删除您发布的信息、喵任务奖金清零、暂停您的账号权限、终止您的账号服务等。若因此造成“全倾”损失或发生相应费用的，您应当全额赔偿。
</b></p>

<p><span>
6、当您在使用“全倾”平台过程中，向“全倾”提供除个人信息之外的一切信息，包括但不限于上传之图片、文章、职位等，您认可自提供之日起即授予“全倾”就该等信息永久性的、全球的、不可撤销的、免使用费的、可再次授权给他人的使用权。
</span></p>


<h5>四、隐私条款</h5>
<p><span>
1、在您使用“全倾”平台过程中，我们有可能需要您填写能识别用户身份的个人信息，以便可以在必要时联系您或为您提供更好的服务。前述“个人信息”包括但不限于您的姓名/企业名称/企业负责人姓名、性别、年龄、联系电话、邮箱、地址、受教育情况、简历信息、求职意向、手机设备识别码、IP地址、简历、用户聊天记录、投诉及评论信息、站内搜索关键词信息等资料。
</span></p>

<p><b>
2、我们重视对您信息的保护，您向“全倾”提供的个人信息，我们将依《全倾隐私权政策》进行保护与规范，详情请参阅第五条《全倾隐私权政策》。
</b></p>


<p><span>
3、如果您是企业用户，我们将依法保护您的商业秘密，非经您同意不会对外提供，但在法律、行政法规要求下，基于民事或刑事调查、监管需要等，应有管辖权的法院、仲裁机构、政府机关、司法机关或监管机构的要求进行的披露除外。
</span></p>


<h5>五、全倾隐私权政策</h5>

<p><span>
全倾非常重视您的个人隐私，并承诺依法采取相应的安全保护措施来保护您的个人信息。在使用全倾软件的服务时，全倾可能会收集和使用下列与您有关的信息。本隐私条款与您所使用的全倾服务息息相关，当您使用或继续使用全倾的服务，即表明同意全倾按照本隐私条款收集、储存和使用您的相关信息。
</span></p>

<p><span>
（一）信息的收集与使用
</span></p>

<p><span>
1、我们需要收集那些信息
</span></p>

<p><span>
为向您提供服务并确保您的服务安全，在您使用全倾服务过程中，全倾会收集您在使用服务过程中主动输入或因使用服务而产生的信息，包括但不限于以下情形及内容：
</span></p>

<p><span>
（1）当您注册全倾账户时填写及/或使用全倾提供的服务时，全倾会收集包括但不限于您的电话号码、用户名信息、简历信息及企业客户的机构工商信息、负责人真实姓名信息等，以帮助您完成全倾注册，如果您拒绝提供这些信息，您可能无法正常使用全倾的服务。
</span></p>

<p><span>
（2）在您使用全倾平台服务，或访问平台网页时，全倾自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、实际所在位置的信息、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；如您下载或使用全倾或其关联公司客户端软件，或访问移动网页使用全倾平台服务时，全倾可能会读取与您位置和移动设备相关的信息，包括但不限于设备型号、设备识别码、操作系统、分辨率、电信运营商等。
</span></p>

<p><span>
（3）当您使用全倾提供的实名认证服务时，全倾需要您提供您的姓名、证件类型、证件号码等信息。如您不提供上述信息，全倾将无法向您提供需完成实名认证后方可使用的产品或服务。
</span></p>

<p><span>
（4）当您使用全倾提供的搜索服务时，全倾会收集您的搜索关键词信息。当搜索关键词信息无法单独识别您的个人身份时，该信息不属于您的您信息，全倾可以对其加以其他目的使用；当搜索关键词信息与您的其他信息结合可以识别您的个人身份时，全倾将在结合期间将您的搜索关键词信息作为您的您信息，对其加以保护处理。
</span></p>

<p><span>
（5）当您使用基于地理位置的功能时，全倾会收集您的位置信息，目的是为了向您提供所在位置的相应服务。如您拒绝提供该信息，您将无法使用上述功能，但这不影响您正常使用全倾的其他功能。
</span></p>

<p><span>
</span></p>

<p><span>
（6）当您通过全倾进行信息管理时，如修改登录密码等操作，全倾会验证您的原登录密码、签约手机号短信验证码。
</span></p>

<p><span>
（7）全倾的电话客服和售后功能会使用您的账号信息和订单信息。为保证您的账号安全，全倾客服会使用您的账号信息与您核验您的身份。当您需要全倾提供与您订单信息相关的客服与售后服务时，全倾将会查询您的订单信息。您有可能会在与全倾的客服人员沟通时，提供除上述信息外的其他信息，例如：当您要求全倾变更联系人或联系电话时。
</span></p>

<p><span>
（8）除上述信息外，全倾还可能为了提供服务及改进服务质量的合理需要而收集您的其他信息，包括您与全倾的客户服务团队联系时所提供的相关信息，您参与问卷调查时向全倾发送的问卷答复信息，以及您与全倾的关联方、全倾合作伙伴之间互动时全倾收集的相关信息。与此同时，为提高您使用全倾平台提供的服务的安全性，更准确地预防钓鱼网站欺诈和木马病毒，全倾可能会通过了解一些您的网络使用习惯、您常用的软件信息等手段来判断您账户的风险，并可能会记录一些全倾认为有风险的URL。
</span></p>

<p><span>
（9）当您在全倾中使用第三方提供的服务时，第三方可能会获取您的昵称、头像等公开信息；如您已明示同意该第三方可以获取您的地理位置信息，全倾将授予该第三方获得终端地理位置信息接口，其可以通过该接口获得您的具体位置信息；在经过您的明示同意后，第三方可获取您的手机号等信息；对于您在使用第三方提供的服务时主动提供给第三方的相关信息，全倾将视为您允许该第三方获取上述此类信息；对于您在使用该第三方服务时产生的信息，应由您与该第三方依法约定上述信息的收集和使用事项。全倾将不为其他网站的隐私权保护规定负责。如您拒绝第三方在提供服务时收集、使用或者传递上述信息，将可能会导致您无法在全倾中使用第三方的相应服务，但这不影响您使用全倾的其他功能。
</span></p>

<p><span>
（10）为了给您提供更好、更优、更加个性化的服务，或共同为您提供服务，或为了预防互联网欺诈的目的，全倾的关联方、合作伙伴会依据法律的规定或与您的约定或征得您同意的前提下，向全倾分享您的个人信息。
</span></p>

<p><span>
2、信息如何使用
</span></p>

<p><span>
（1）全倾收集的您的信息均用于我们向您提供的服务之中，并为改进这些产品或服务时使用。为提升您的产品或服务体验，或为防范风险，全倾会对服务使用情况进行汇总、统计分析、加工，但这些信息不会包含您的任何身份识别信息。
</span></p>

<p><span>
（2）在全倾向您提供服务期间，您授权全倾持续收集和使用您的信息。在您注销服务时，我们将停止收集您相关的个人信息，但全倾会在业务资料归档、审计、监管协查等领域继续使用此前收集的您的相关个人信息。
</span></p>

<p><span>
（3）全倾可能使用您提供的个人信息中的联系方式与您通信，例如通知您有关您的账户、安全性更新、产品和服务信息，向您及时发送重要通知，如软件更新、本协议条款的变更。
</span></p>

<p><span>
（4）为了使您知悉使用全倾产品或服务的情况或使您进一步了解全倾服务，全倾会向您发送服务状态通知以及相关产品或服务的商业性信息。全倾将向您推荐您可能感兴趣的内容，包括但不限于通过系统向您展示个性化的第三方推广信息，或者根据本条款与全倾的合作伙伴共享信息以便他们向您发送有关其产品和服务的信息。
</span></p>

<p><span>
（5）为了改善全倾的技术和服务，向您提供更好的服务体验，全倾可能会与第三方合作向您提供相关的服务，在此情况下，如全倾旗下相应主体已与该第三方签署了适当的保密条款，则全倾有权将您的注册信息等资料提供给该第三方，并要求该第三方按照全倾的隐私保护条款以及其他任何相关的保密和安全措施来为全倾处理上述信息。
</span></p>

<p><span>
（6）全倾可能使用您的个人信息以预防、发现、调查欺诈、危害安全、非法或违反与全倾或其关联方协议、政策或规则的行为，以保护您、其他全倾用户，或全倾或其关联方的合法权益。
</span></p>

<p><span>
（7）如果您向全倾提交了您的简历信息，您同意使用全倾平台上相应服务的企业或人员可以通过全倾的简历数据库找到您的简历。当您在全倾填写或提交您的简历信息，并公开您的简历时，您已经同意向使用全倾平台上相应服务的企业或人员拥有法律上许可的对您的简历进行查询、使用、问询的权利。对于因此而引起的任何法律纠纷（包括但不限于招聘企业或人员错误或非法使用前述简历信息），全倾不承担任何法律责任。
</span></p>

<p><span>
（8）尽管全倾已经作了相应的安全防范措施，仍可能不可避免某一第三方躲过了全倾的安全措施并进入全倾的数据库查找到您的简历。全倾认为在您把您的简历放入全倾的数据库时，您已经意识到并同意承担这样的风险。对于因此而引起的任何法律纠纷，全倾不承担任何法律责任。
</span></p>

<p><span>
（9）您授权同意的以及于法律允许的其它用途。
</span></p>

<p><span>
（二）全倾如何使用Cookie技术
</span></p>

<p><span>
“Cookies”是网站为了纪录客户所传送至客户电脑硬盘上的一小段资料。Cookies可以让网站记住某些关于客户的资讯，使客户在浏览网页的时候更为便利。就如同现今绝大多数的网站一样，全倾使用了Cookies技术以便让您有一个更为愉快的网络体验。如果不愿意接受Cookies的话，请使用网路浏览器中的选项，关闭接受Cookies的选项，或是将选项选择为在接收到Cookies的时候通知使用者。请点选浏览器中的“说明”选项以了解关于调整接受Cookies的方法。如果选择不接收Cookies，客户将可能无法享受网站上所提供的某些便利功能。通过全倾所设Cookies所取得的有关信息，将适用本政策；在全倾上发布广告的公司或个人通过广告在您计算机上设定的Cookies，将按其自己的隐私权政策使用。
</span></p>

<p><span>
（三）全倾如何共享、转让和公开披露您的个人信息
</span></p>

<p><span>
1、共享和转让
</span></p>

<p><span>
（1）除非获得您的明确同意或授权，全倾不会向其他任何公司、组织和个人共享或转让您的个人信息。
</span></p>

<p><span>
（2）如业务需要对外共享或转让您的个人信息，我们会向您告知共享或转让个人信息的目的、数据接收方的类型，并征得您的授权同意。涉及敏感信息的，我们还会告知敏感信息的类型、数据接收方的身份和数据安全能力，并征得您的明示授权同意。
</span></p>

<p><span>
（3）根据法律法规和商业惯例，在合并、收购、资产转让等类似交易中，如涉及到个人信息转让，全倾会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束。
</span></p>

<p><span>
2、公开披露
</span></p>

<p><span>
（1）我们不会公开披露您的个人信息，如确需披露，全倾会告知您披露个人信息的目的、类型；涉及敏感信息的还会告知敏感信息的内容，并事先征得您的明示同意。
</span></p>

<p><span>
（2）在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
</span></p>

<p><span>
3、征得授权同意的例外
</span></p>

<p><span>
根据相关法律法规、监管要求及国家标准，以下情形中，全倾可能会收集、使用您的相关个人信息而无需另行征求您的授权同意：
</span></p>

<p><span>
（1）与国家安全、国防安全直接相关的；
</span></p>

<p><span>
（2）与公共安全、公共卫生、重大公共利益直接相关的；
</span></p>

<p><span>
（3）与犯罪侦查、起诉、审判和判决执行等直接相关的，或根据行政、司法机构的要求，向第三方或者行政、司法机构披露；如：您是具备资格的知识产权投诉人并已提起投诉，应被投诉人要求而向被投诉人披露，以便双方处理可能的权利纠纷；
</span></p>

<p><span>
（4）在全倾上创建的某一分类信息交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，全倾有权可以决定向该您提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。
</span></p>

<p><span>
（5）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
</span></p>

<p><span>
（6）所收集的个人信息是您自行向社会公众公开的；如：由于您将其您密码告知他人或与他人共享注册帐户与密码，由此导致的任何个人信息的泄漏，或其他非因全倾原因导致的个人隐私信息的泄露；
</span></p>

<p><span>
（7）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
</span></p>

<p><span>
（8）根据您要求签订和履行合同所必需的；
</span></p>

<p><span>
（9）用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
</span></p>

<p><span>
（10）为开展合法的新闻报道所必需的；
</span></p>

<p><span>
（11）出于公共利益开展统计或学术研究所必需，且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
</span></p>

<p><span>
（12）法律法规及监管要求规定的其他情形。
</span></p>

<p><span>
（四）全倾如何存储和保护您的个人信息
</span></p>

<p><span>
1、存储
</span></p>

<p><span>
（1）全倾在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。但为处理跨境业务并在获得您的授权同意后，您的个人信息可能会被转移到境外。此种情况下，全倾会采取有效措施保护您的信息安全，例如，在跨境数据转移之前实施数据去标识化等安全措施，或通过协议、核查等措施要求境外机构为您的信息保密。
</span></p>

<p><span>
（2）全倾仅在法律法规要求的期限内，以及为实现本政策声明的目的所必须的时限内保留您的个人信息。例如：
</span></p>

<p><span>
手机号码：当您需要使用全倾服务时，全倾需要一直保存您的手机号码，以保证您正常使用该服务，当您注销账户后，全倾将删除相应的信息；
</span></p>

<p><span>
您的头像：当您设置了头像以后，全倾会一直保存您的头像，用于登录页面展示使用。当您选择恢复默为认头像后，全倾将删除您设置的头像信息。
</span></p>

<p><span>
2、保护
</span></p>

<p><span>
（1）全倾已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。全倾会采取一切合理可行的措施，保护您的个人信息。例如：全倾会使用加密技术确保数据的保密性；全倾会使用受信赖的保护机制防止数据遭到恶意攻击；全倾会部署访问控制机制，确保只有授权人员才可访问个人信息；以及全倾会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
</span></p>

<p><span>
（2）如全倾提供的全部或部分业务停止运营，全倾相关产品或服务将通过公告等形式向您进行告知，同时停止相关产品或服务对您个人信息的收集等操作，保护您的个人信息安全。如因技术故障、网络攻击、自然灾害及事故、人为因素等各种原因造成全部或部分业务中断，全倾将采取应急处置和恢复措施予以应对，尽快恢复服务。
</span></p>

<p><span>
（3）我们会采取一切合理可行的措施，确保未收集无关的个人信息。
</span></p>

<p><span>
（4）在使用全倾服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对方提供自己的个人信息，如联络方式或者邮政地址等。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。请您理解，由于技术水平局限以及可能存在的恶意攻击，有可能出现全倾无法合理预见、防范、避免、控制的意外情况。互联网并非绝对安全的环境，请使用复杂密码，协助全倾保证您的账号安全。若发生您信息被泄露或盗用的情况，全倾也提供诸如自动系统举报删帖、客服电话等服务配合您的信息安全保护工作。
</span></p>

<p><span>
（5）如发生个人信息安全事件，全倾将按照法律法规的要求，及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，或采取合理、有效的方式发布公告。同时，全倾还将依据监管规定，上报个人信息安全事件处置情况。
</span></p>

<p><span>
（五）您控制个人信息的权利
</span></p>

<p><span>
按照中国相关的法律法规和监管规定，全倾保障您对自己的个人信息行使以下权利：
</span></p>

<p><span>
1、访问、更正及更新您的个人信息
</span></p>

<p><span>
您有权通过全倾访问及更正、更新您的个人信息，法律法规另有规定的除外。您有责任及时更新您的个人信息。在您修改个人信息之前，我们会验证您的身份。
</span></p>

<p><span>
2、删除您的个人信息
</span></p>

<p><span>
在以下情形中，您可以向全倾提出删除个人信息的请求：
</span></p>

<p><span>
（1）如果全倾处理个人信息的行为违反法律法规；
</span></p>

<p><span>
（2）如果全倾收集、使用您的个人信息，却未征得您的同意；
</span></p>

<p><span>
（3）如果全倾处理个人信息的行为违反了与您的约定；
</span></p>

<p><span>
（4）如果我们不再为您提供产品或服务。
</span></p>

<p><span>
如我们决定响应您的删除请求，我们还将同时通知从全倾获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
</span></p>

<p><span>
当您从我们的服务中删除信息后，我们可能不会立即在备份系统中删除相应的信息，但会在备份更新时删除这些信息。
</span></p>

<h5>六、服务费用</h5>
<p><span>
1、“全倾”是个对大多数用户免费的分类信息网站，但“全倾”同时提供部分收费服务。若您所使用的服务需支付费用，您有权决定是否使用并接受该收费服务。
</span></p>

<p><span>
2、“全倾”上的收费服务以人民币计价，定价上可能随时调整。我们将以网站公告的方式，来通知您收费政策的变更。“全倾”也可选择在促销或新服务推出时，暂时调整我们的服务收费，该调整于我们公布促销或新服务时开始生效。
</span></p>

<p><span>
3、“全倾”只有在系统接收到您支付的款项后，才开始为您提供您订购的付费服务。
</span></p>

<p><b>
4、“全倾”因网站自身需要进行改版的，若涉及付费产品的实质性变化，包括但不限于产品取消、服务内容发生增加或减少、登载页面变更、发布城市变更的，“全倾”可提前终止服务并将您已付款但未履行服务部分款项退还给您，此类情况不视为全倾违约。
</b></p>


<h5>七、涉税事项告知及授权</h5>
<p><b>
如您通过“全倾”平台向企业端提供劳务或其他服务，如劳务合同约定，客户支付的劳务费用由全倾代发，“全倾”会根据您实际服务情况向您支付对应报酬，支付方式包括但不限于：支付宝、银行、账户余额充值等。在向您支付报酬时，我司会依法依规扣除您该笔报酬收入中您应缴纳之个人所得税，并将对应信息依法上传至国家税务机关系统网站中。您完全知悉且理解前述涉税事项，并同意授权我司为您该等收入进行代扣代缴及税务信息申报等涉税事宜。
</b></p>

<h5>八、侵权通知</h5>
<p><span>
1、如您发现“全倾”平台上的任何内容不符合法律法规规定，不符合本用户协议规定，或不符合“全倾”公布的各项规则规定时，您有权利和义务通过网站底部全倾公示的邮箱contact@quanqingkeji.com向我们申述。   
</span></p>

<p><span>
2、当您发现自己的个人信息被盗用，或者您的版权或者其他权利被侵害，请及时将此情况通过网站底部全倾公示的邮箱contact@quanqingkeji.com报告给“全倾”。请同时提供以下信息：
</span></p>

<p><span>
①　侵犯用户权利的信息的网址，编号或其他可以找到该信息的细节；
</span></p>

<p><span>
②　用户提供所述的版权或个人信息的合法拥有者的声明；
</span></p>

<p><span>
③　用户提供初步能证明侵权的证据；
</span></p>

<p><span>
④　用户的姓名，地址，电话号码和电子邮件等各类有效联系方式；
</span></p>

<p><span>
⑤　用户的签名。
</span></p>

<p><span>
“全倾”会在核实后，根据相应法律法规及本协议约定予以配合处理。若因您提供的证据不充分或填写的联系方式有误等不可归咎于“全倾”的原因，使“全倾”无法对相关情况进行核实的，我们保留暂停处理侵权通知的权利。
</span></p>


<h5>九、知识产权</h5>
<p><span>
1、“全倾”平台上展示的所有内容，包括但不限于版面设计、图标、文字及其组合、产品、技术、程序等，知识产权均受著作权法、商标法、专利法或其他法律法规保护。未经“全倾”书面授权许可, 您不得擅自使用、复制、转载、修改、售卖前述内容。否则，“全倾”有权追究您的法律责任。
</span></p>

<p><span>
2、除另有声明外，“全倾”提供服务时所依托软件的著作权、专利权及其他知识产权均归“全倾”所有。未经明确授权，任何用户不得更改、演绎、拆分、反解“全倾”的技术和程序或以其它任何方式进行可能损害“全倾”专有权利的行为。
</span></p>

<p><span>
3、您在使用“全倾”平台过程中发布的任何内容，均授予“全倾”免费且不可撤销的非独家使用许可，“全倾”有权将改内容用于“全倾”平台的各项产品及服务上。您对“全倾”的前述授权并不改变您发布内容的所有权及知识产权归属。
</span></p>


<h5>十、免责声明</h5>
<p><span>
1、您同意因网络原因、“全倾”进行业务调整、正常的系统维护升级及第三方原因或不可抗力因素等造成的“全倾”平台无法正常访问或“全倾”服务无法正常提供的，“全倾”不承担任何责任。
</span></p>

<p><span>
2、您通过“全倾”平台发布的信息或内容，并不代表“全倾”之意见及观点，也不意味着“全倾”赞同其观点或证实其内容的真实性。您通过“全倾”平台发布的信息、文字、图片等资料均由您提供，其真实性、准确性和合法性由信息发布人或提供者负责。“全倾”不提供任何保证，并不承担任何法律责任。如果以上资料侵犯了第三方的知识产权或其他权利，责任由信息发布者本人承担，“全倾”对此不承担任何法律责任及赔偿责任。
</span></p>

<p><span>
3、“全倾”仅为网络平台提供者，不对您与其他用户或任何第三方之间的任何纠纷（包括但不限于债权债务纠纷、劳资纠纷、人身损害纠纷、意外伤害纠纷等）承担任何法律责任及赔偿责任。对于可证实的纠纷，“全倾”可以根据掌握的情况进行纠纷的评判及协调，协调不成时全倾可以提供相关资料辅助您通过法律手段（如法院起诉等）维护您的正当权益。
</span></p>

<p><span>
4、除“全倾”注明的平台服务内容及条款外，其他一切因使用“全倾”平台所造成的损失，“全倾”概不负责，亦不承担任何法律责任及赔偿责任。任何您使用“全倾”平台服务并不意味与“全倾”及其运营公司产生任何代理、合伙、合资、雇佣、劳动、劳务及劳务派遣之关系。
</span></p>

<p><span>
5、在您使用的过程中，若我们通过广告或其他方式向您提供链接，使您可以接入第三方服务或网站，且您实际使用该等第三方的服务时，须受该第三方的服务条款及隐私条款约束，您需要仔细阅读其条款。您应了解，本协议仅适用于“全倾”提供的服务，并不适用于任何第三方提供的服务或第三方的信息使用规则，“全倾”无法对第三方网站进行控制。因此，“全倾”对任何第三方提供的服务或第三方使用由您提供的信息不承担任何责任。
</span></p>

<p><span>
6、 您清楚“全倾”平台内容大多数内容来自用户，“全倾”不保证平台上展示之信息的准确性和有效性、以及所提供内容质量的安全性或合法性。您如因为浏览“全倾”的内容或第三方发布和上传的内容而因此遭受到任何损失（包括但不限于金钱、商誉、名誉的损失），或与其他用户发生争议，就上述损失或争议或任何方面产生有关的索赔、要求、诉讼、损失和损害，我们可为您提供相关资料处理争议事宜。您同意不就前述争议事件追究“全倾”的责任，同意免除“全倾”平台运营方北京全倾科技有限公司及其关联公司的责任。
</span></p>


<h5>十一、违约责任</h5>
<p><span>
1、如因您违反有关法律、法规或本协议项下的任何条款给“全倾”或任何第三方造成损失，“全倾”有权随时采取停止服务、删除或修改信息、解除本协议等措施，并保留向您追索赔偿一切损失之权利。同时“全倾”已向您收取的服务费（如有）将不予退还，您应自行承担由此造成的一切法律责任。
</span></p>

<p><span>
2、如因“全倾”违反有关法律、法规或本协议项下的任何条款而给您造成损失的，相应的损害赔偿责任由“全倾”承担。
</span></p>

<h5>十二、通知</h5>
<p><span>
1、“全倾”提供服务有关的用户协议和服务条款的修改、服务的变更、收费政策的变更或其它重要事件的通告都会以网站发布、用户后台或通过其他联系方式向您通知/送达的相关规则、政策、通知，前述修订或相关规则，自通知/送达之日起正式生效。
</span></p>

<p><span>
2、您在使用“全倾”服务时，应向“全倾”提供真实有效的联系方式（包括但不限于联系电话等），若您的联系方式发生变更的，您应该及时更新有关信息，便于“全倾”及时向您发送各类通知。
</span></p>


<h5>十三、争议的解决及其他</h5>
<p><span>
1、本协议的所有内容和条款均受中华人民共和国法律管辖。与“全倾”内容、服务相关的争议、“全倾”所有用户间的争议、网站与用户间的争议等全部相关争议均不可撤销地受“全倾”所有权和运营权所有者北京全倾科技有限公司实际经营地所在地人民法院的管辖。您自愿放弃选择以网络终端、服务器所在地、侵权行为所在地等其它地点作为相关管辖地的权利。
</span></p>

<p><span>
2、如本协议的任何条款被裁定为无效或不可执行，该条款应视为可分的，且不影响本协议其余条款的有效性及可执行性。
</span></p>

<p><span>
3、在发生并购时，本协议和所有纳入的协议所确定的权利可由““全倾””自行酌情决定向第三方转让。“全倾”未就用户或其他方的违约行为采取行动并不等于“全倾”放弃就随后或类似的违约行为采取行动的权利。
</span></p>

<p><span>
4、“全倾”不定期发布针对用户的相关协议、公告、规则等，并可将前述文件作为本协议的补充或修改而将其内容作为本协议的一部分。
</span></p>
`
