<template>
  <div class="check-box">
    <ul class="checkbox-container">
      <li
        class="checkbox-item"
        v-for="(item, i) in defaultList"
        :key="`hoverstyle${i}`"
        style="margin-bottom: 15px;"
        @mouseenter="hoverAction(item.introduction)"
        @mouseleave="hoverAction('')"
        :class="`${hasChecked(item.id) ? 'checked' : ''}`"
        @click="() => toggleSelect(item)"
      >
        {{ item.name }} <i v-if="item.tag">({{ item.tag }} )</i>
      </li>
    </ul>
  </div>
</template>

<script>
// 多选单选 isMultiple 默认单选
// 多选列表为对象，需要提供渲染子字段 showKey，列表为字符串或数字，则直接渲染
// 选中结果统一传入和传出都为数组形式
export default {
  props: ['checked', 'text', 'isMultiple', 'defaultList', 'checkedList'],
  name: 'checkButton',
  computed: {},
  data() {
    return {}
  },
  methods: {
    toggleSelect(item) {
      console.log('update:checkedList', [...this.checkedList, item])
      if (this.isMultiple) {
        if (this.hasChecked(item.id)) {
          this.$emit(
            'update:checkedList',
            this.checkedList.filter(i => i.id != item.id)
          )
        } else {
          this.$emit('update:checkedList', [...this.checkedList, item])
        }
      } else {
        this.$emit('update:checkedList', [item])
      }
    },
    hasChecked(id) {
      return this.checkedList.some(item => item.id == id)
    },
    hoverAction(text) {
      this.$emit('hoverIntroduction', text)
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;

  .checkbox-item {
    height: 46px;
    line-height: 46px;
    background: #d3d3d3;
    border-radius: 23px;
    padding-left: 38px;
    padding-right: 38px;
    margin-right: 50px;
    color: #ffffff;
    font-size: 20px;
  }

  .checked {
    background: #5ad3cd;
  }
}
</style>
