<template>
  <layoutContainer>
    <h4 class="aboutTitle">{{ baseData.title }}</h4>
    <div class="aboutText" v-html="baseData.content"></div>
  </layoutContainer>
</template>
<script>
import { registerContent } from './js/register'
import { aboutContent } from './js/about'
import { companyContent } from './js/company'
import { employContent } from './js/employ'
import { connectionContent } from './js/connection'

export default {
  data() {
    return {
      baseData: {
        title: '',
        content: ``
      },
      baseObject: {
        quanqingProtocol: {
          title: '全倾用户协议及隐私政策',
          content: registerContent
        },
        quanqingAbout: {
          title: '全倾介绍',
          content: aboutContent
        },
        connectionQuanqing: {
          title: '联系全倾',
          content: connectionContent
        },
        companyCommission: {
          title: '公司推广分佣',
          content: companyContent
        },
        employCommission: {
          title: '个人推广分佣',
          content: employContent
        }
      }
    }
  },
  created() {
    this.formatContent()
  },
  methods: {
    formatContent() {
      const path = this.$route.path.slice(1)
      this.baseData = JSON.parse(JSON.stringify(this.baseObject[path]))
    }
  },
  watch: {
    '$route.path'() {
      this.formatContent()
    }
  }
}
</script>
<style lang="scss" scoped>
.aboutTitle {
  width: 100%;
  height: 200px;
  background: #37c2bc url('/img/Mask.png') no-repeat;
  margin-top: 20px;
  font-size: 35px;
  line-height: 200px;
  text-align: center;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
}

.aboutText {
  width: 100%;
  padding: 50px 100px;
  background: #fff;
  margin-bottom: 120px;
  text-indent: 36px;
  line-height: 35px;
  font-size: 18px;
}

.aboutText p {
  padding: 10px 0;
}

.aboutText p b {
  font-weight: 600 !important;
}

.aboutText h5 {
  font-weight: bold !important;
}
</style>
