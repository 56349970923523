<template>
  <div class="check-box">
    <p>{{ title }}</p>
    <li class="hoverstyle active box active_child" v-if="checkedName && !isParentName(checkedName)">
      {{ checkedName }}
      <i v-if="showCloseIcon" @click.stop="() => updateActiveIndex('')" class="el-icon-error close-icon"></i>
    </li>
    <ul class="item-box" :class="isShowMore ? 'isShowMore' : ''">
      <li
        class="hoverstyle box"
        :class="i == 0 ? 'first-select' : ''"
        v-for="(item, i) in list"
        :key="`hoverstyle${i}`"
        style="margin-bottom: 15px;"
      >
        <span
          v-if="!item.children.length"
          @click="updateActiveIndex(item.id)"
          :class="activeIndex == item['id'] ? 'active box' : 'box'"
        >
          {{ item[keyname] }}
        </span>
        <i
          v-if="checkedName === item[keyname] && showCloseIcon"
          @click.stop="() => updateActiveIndex('')"
          class="el-icon-error close-icon list-close"
        ></i>
        <el-popover
          popper-class="popover box"
          placement="bottom"
          width="840"
          trigger="hover"
          v-if="item.children.length"
        >
          <div class="pleasehloder"></div>
          <div class="children-content">
            <div class="children-option" v-for="itm in item.children" :key="itm.id">
              <div class="sub-title " @click="updateActiveIndex(itm.id)">
                <p class="point"></p>
                <span>{{ itm.name }}</span>
              </div>
            </div>
          </div>
          <span
            slot="reference"
            @click="updateActiveIndex(item.id)"
            class="box"
            :class="checkedName == item[keyname] ? 'active' : ''"
          >
            {{ item[keyname] }}
          </span>
        </el-popover>
      </li>
      <li class="more" v-if="needShowMoreTool" @click="isShowMore = !isShowMore">
        <span v-if="!isShowMore">收起<i :class="'el-icon-arrow-up'"></i></span>
        <i v-if="isShowMore" :class="'el-icon-more'"></i>
      </li>
    </ul>
  </div>
</template>

<script>
// 对于多层嵌套模型，需要提供原始数据模型和拍平展开之后的数据模型
// v-if 选中时只展示选中状态的
// v-else 不提供选中状态默认选中全部，正常展示第一层的循环，划过时候展示父级 id 相同的子集
import {mapState} from 'vuex'

export default {
  props: [
    'title',
    'list',
    'flatList',
    'activeIndex',
    'keyname',
    'showCloseIcon',
    'orderId',
    'canCheckPackage',
    'payedOrderId'
  ],
  name: 'checkRadioHoverGroup',
  computed: {
    ...mapState(['UserInfo']),
    needShowMoreTool() {
      // 需要动态获取dom 高度去计算
      return true
    },
    checkedName() {
      const checked = (this.activeIndex && this.flatList.filter(item => item.id == this.activeIndex)) || []
      return checked[0]?.name
    }
  },
  data() {
    return {
      isShowMore: true,
      selectCategoryName: ''
    }
  },
  watch: {},
  methods: {
    mouseover(row) {
      if (this.activeIndex) {
        return
      }
    },
    updateActiveIndex(id, notChooseParnet) {
      if (this.packageId == id) return
      if (notChooseParnet || id == this.activeIndex) {
        return
      }
      if (this.payedOrderId) {
        this.$alert('项目的质检标准订单已支付，不能再更换包（如需更换，可先将订单退款）', {
          confirmButtonText: '确定',
          type: 'warning'
        })
        return
      }
      if (this.orderId) {
        this.$alert('订单已完成，不能再将包加入到选购区', {
          confirmButtonText: '确定',
          type: 'warning'
        })
        return
      }
      if (!this.canCheckPackage) {
        this.$alert('您没有该项目权限，不能将标准包加入到选购区或切换标准包！', {
          confirmButtonText: '确定',
          type: 'warning'
        })
        return
      }
      if (!this.UserInfo.id) {
        this.$router.push('/login')
        return
      }
      if (id && !this.activeIndex) {
        this.$emit('update:activeIndex', id)
      } else {
        let confirmText
        let successText
        let cancelText

        if (!id) {
          // confirmText = '确定要移除此标准包吗？'
          // successText = '移除成功!'
          // cancelText = '已取消删除'
          this.$emit('getStandardBuyListCancelPackage')
          return
        }
        if (id && this.activeIndex != id) {
          confirmText = '确定更换标准包吗？'
          successText = '更换成功!'
          cancelText = '已取消更换'
        }
        this.$confirm(confirmText, '', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            console.log('切换id......', id)
            this.$emit('update:activeIndex', id)
            this.$message({
              type: 'success',
              message: successText
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: cancelText
            })
          })
      }
    },
    isParentName(checkedName) {
      return this.list.filter(item => item.name == checkedName).length
    }
  }
}
</script>

<style lang="scss" scoped>
.check-box {
  display: flex;
  padding: 5px 0;

  p {
    width: 160px;
    // margin-right: 20px;
    padding-left: 40px;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
  }

  ul {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    height: auto;
  }

  li {
    position: relative;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }

  .active {
    background: #55cbc4;
    color: #fff;
    border-radius: 4px;
  }
}

.box {
  height: 36px;
  padding: 5px 22px;
}

.item-box {
  position: relative;
  padding-right: 40px;

  li.more {
    position: absolute;
    right: -20px;
    top: 0;
    width: 100px;
    text-align: center;
    cursor: pointer !important;
  }
}

.isShowMore {
  height: 45px !important;
  overflow: hidden;
}

.close-icon {
  position: absolute;
  right: -25px;
  top: 8px;
  // margin-left: 15px;
  color: #666666;
  z-index: 1000;
}

.pop-box {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.children-content {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  padding: 0 40px 36px;
}

.sub-title {
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 25px;
  margin-bottom: 20px;
  cursor: pointer;
}

.sub-title span {
  max-width: 124px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}

.point {
  width: 8px;
  height: 8px;
  background: #5ad3cd;
  margin: 7px 8px 0 0;
  display: inline-block;
  border-radius: 50%;
  vertical-align: top;
}

.children-option {
  width: 141px;
  margin-right: 10px;
}

.children-name {
  margin-bottom: 20px;
  display: inline-block;
  overflow: hidden;
  width: 141px;
}

.option-name {
  display: inline-block;
  max-width: 99px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  vertical-align: top;
}

.hot {
  width: 36px;
  height: 18px;
  background: rgba(255, 114, 76, 0.1);
  border-radius: 10px;
  border: 1px solid #ff724c;
  font-size: 13px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ff724c;
  line-height: 16px;
  text-align: center;
  display: inline-block;
  margin-left: 6px;
  vertical-align: top;
}

.clild-name {
  padding: 0 !important;
}

.clild-name1 {
  padding: 5px 22px;
}

/deep/.el-popover {
  padding: 0 !important;
}
</style>
<style>
.popover {
  box-shadow: 6px 12px 10px 0px rgba(203, 203, 203, 0.36) !important;
  border-radius: 10px !important;
  padding: 0 !important;
}

.pleasehloder {
  height: 36px;
  width: 100%;
  background: linear-gradient(180deg, #ffffff 0%, #e3e3e3 33%, #ffffff 68%, #ffffff 100%);
  opacity: 0.2;
  margin-top: -6px;
}

.first-select {
  padding-left: 0 !important;
}

.list-close {
  right: -5px !important;
}

.active_child {
  margin-right: 40px;
}
</style>
