<template>
  <div class="pay-container" v-if="showPayDialog">
    <div class="pay-content">
      <div class="pay-type">
        <div
          v-for="item in payTypeList"
          :key="item.id"
          @click="changePayType(item)"
          :class="[choosePayType.id === item.id && 'choose-pay']"
        >
          <img :src="item.icon" draggable="false" />
          <span>{{ item.name }}</span>
        </div>
      </div>
      <div class="pay-qr-code">
        <img :src="paySrc" v-if="choosePayType.id === 'weixin'" />
        <div class="pay-detail">
          <div class="pay-price">
            {{ choosePayType.name }}扫码，支付 <span> {{ orderPrice }} </span> 元
          </div>
          <div class="pay-tip">
            30天未使用可退款，详见
            <span @click="openPayProtocol">《服务说明》</span>
            <el-button v-if="choosePayType.id === 'zhifubao'" class="pay_button" @click="payOrder">去支付</el-button>
            <div class="pay-html" v-html="payHtml"></div>
          </div>
        </div>
      </div>
      <i class="el-icon-close close-button" @click="closeDialog"></i>
    </div>
  </div>
</template>

<script>
// todo:  如何防止订单重复提交重复缴费,订单号作为判断识别依据（异步行为）
import {formatTimeFilter} from '@/utils/index.js'
import {orderPay, orderDetail} from '@/api/subject'
import {mapState} from 'vuex'

export default {
  name: 'standardPay',
  props: {
    showPayDialog: {
      type: Boolean,
      default: false
    },
    orderCode: {
      type: String,
      default: ''
    },
    orderPrice: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      payHtml: '',
      vipInfo: {
        expirationDate: '',
        username: '',
        hasEffected: false
      },
      priceInfoList: [],
      payTypeList: [
        {
          name: '微信',
          icon: require('../../../assets/weixin.png'),
          id: 'weixin'
        },
        {
          name: '支付宝',
          icon: require('../../../assets/zhifubao.png'),
          id: 'zhifubao'
        }
      ],
      choosePrice: {},
      choosePayType: {},
      paySrc: '', // 微信二维码
      payTimer: null
    }
  },
  watch: {
    UserInfo: {
      deep: true,
      immediate: true,
      handler() {
        if (this.UserInfo && this.UserInfo.id) {
          // rechargeListApi().then(res => {
          //   this.priceInfoList = res.data.priceInfoList
          //   this.choosePrice = res.data.priceInfoList[0]
          //   this.choosePayType = this.payTypeList[0]
          //   this.pay()
          //   Object.assign(this.vipInfo, {
          //     expirationDate: res.data.expirationDate,
          //     username: res.data.username,
          //     hasEffected: res.data.hasEffected
          //   })
          // })
        }
      }
    }
  },
  computed: {
    ...mapState(['UserInfo'])
  },
  created() {
    this.changePayType(this.payTypeList[0])
    this.checkPay()
  },
  beforeDestroy() {
    this.payTimer && clearInterval(this.payTimer)
    this.payTimer = null
  },
  methods: {
    formatDate(time) {
      return formatTimeFilter(time, 'YYYY-MM-DD')
    },
    checkPay() {
      if (this.UserInfo && this.UserInfo.id) {
        if (!this.payTimer) {
          this.payTimer = setInterval(() => {
            orderDetail(this.orderCode).then(res => {
              console.log(`订单  ${this.orderCode}   支付结果`, JSON.stringify(res, null, 2))
              if (res.data.paySuccess == true) {
                this.payTimer && clearInterval(this.payTimer)
                this.payTimer = null
                this.$dialog.toast('支付成功')
                this.$emit('update:showPayDialog')
                // 检测到当前订单已支付成功，则跳转 订单列表页
                this.$router.replace(`/orderList`)
              }
            })
          }, 1000)
        }
      }
    },
    changePayType(type) {
      this.choosePayType = type
      type.id === 'weixin' && this.payOrder()
    },
    // 支付宝支付
    payOrder() {
      const params = {
        payType: this.choosePayType.id === 'weixin' ? 2 : '1',
        orderCode: this.orderCode,
        alipayReturnUrl: `${window.location.origin}/#/orderList?orderId=${this.orderCode}`
      }
      orderPay(params).then(item => {
        console.log('调起支付..........', item)
        // 微信支付
        if (this.choosePayType.id === 'weixin') {
          this.paySrc = item.data
          return
        } else {
          this.payHtml = item.data
          this.$nextTick(() => {
            document.punchout_form.submit()
          })
        }
      })
    },
    openPayProtocol() {},
    closeDialog() {
      this.$emit('update:showPayDialog')
    }
  }
}
</script>

<style lang="scss" scoped>
.pay-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);

  .pay-content {
    position: relative;
    left: 0;
    top: 0;
    width: 720px;
    height: 316px;
    margin: 0 auto;
    background: #f4f5f5;
    box-shadow: 0px 8px 21px 1px rgba(220, 220, 220, 0.5);
    border-radius: 20px;
    display: flex;
    justify-content: space-between;

    .pay-type {
      width: 220px;
      border-radius: 20px 0 0 20px;

      div {
        padding: 28px 38px;
        font-size: 28px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:first-child {
          border-radius: 20px 0 0 0;
        }

        img {
          width: 36px;
          height: 30px;
          object-fit: contain;
          margin-right: 10px;
        }
      }

      .choose-pay {
        background: #ffffff;
      }
    }

    .pay-qr-code {
      background-color: #fff;
      flex: 1;
      padding: 40px 0 60px 32px;
      display: flex;
      flex-direction: column;
      border-radius: 0 20px 20px 0;

      img {
        width: 140px;
        height: 140px;
        object-fit: contain;
        margin-right: 12px;
      }

      .pay-detail {
        margin-right: 10px;
        padding-left: 10px;

        .pay-price {
          font-size: 26px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          margin-bottom: 8px;

          span {
            &:first-child {
              color: #ff0000;
              font-size: 38px;
            }
          }
        }

        .pay-tip {
          color: #999999;
          font-size: 22px;

          span {
            color: #333333;
            cursor: pointer;
          }

          button {
            display: block;
            width: 130px;
            height: 42px;
            background: #5ad3cd;
            border-radius: 21px;
            margin-top: 24px;
            cursor: pointer;
            color: #fff;
          }
        }
      }
    }
  }

  .close-button {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 30px;
  }
}
.pay_button {
  font-size: 20px;
  border: none;
}
</style>
